<template>
	<b-container v-if="$checkPermission('list-template')" fluid id="template">
		<cg-loader :display="loading"></cg-loader>
		<cg-localized-alert :alertVariant="alertVariant" :alertParams="alertParams" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>
		<cg-template-modal 
			:template="template" 
			:companyData="companyData" 
			:searchText="filter" 
			:repo="imageRepoList" 
			ref="cgTemplateModal" 
			@reload="reloadTemplate"
			@refresh="getTemplates" 
			@copied="copied" 
			@repository="updateRepo">
		</cg-template-modal>
		<cg-modal :data="newTemplateFields" @update="createTemplate"></cg-modal>

		<div class="px-4 mb-4">
			<b-card-title class="template-title mb-4">{{ $t('Template.Title') }}</b-card-title>

			<!-- Template Options -->
			<b-row align-h="end" class="mb-4">
				<b-col>

					<!-- File browser for Template import/export -->
					<b-row align-h="end">
						<b-col class="col-auto mb-3">
							<b-input-group size="sm">
								<b-button v-if="$checkPermission('create-template')" @click="createNewTemplate" size="sm" variant="primary">
									<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t("Template.NewTemplate") }}
								</b-button>
								<b-button variant="transparent" size="sm" class="ml-2" @click="showOptions=!showOptions">
									<span class="sr-only">{{ $t('Template.Options') }}</span>
									<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'ellipsis-v']" />
								</b-button>
							</b-input-group>
						</b-col>
					</b-row>

					<transition name="slide-fade">
						<div v-show="showOptions" class="template-menu pt-3 mb-2">
							<!-- File browser for Template import/export -->
							<b-form-group label-cols-sm="3" label-align-sm="right" label-size="sm">
								<b-input-group size="sm">
									<b-form-file
										v-model="templatesXml"
										v-if="$checkPermission('import-template')"
										:placeholder="$t('Template.ImportXML')"
										accept=".xml"
										size="sm"
										class="mr-2"
										id="templatesXml"
										ref="templatesXml"
										@input="importTemplates"
									></b-form-file>
									<b-button id="exportTemplates" v-if="$checkPermission('export-template,list-company-all')" variant="primary" size="sm" class="w-25" href="api/template/export">
										<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'download']" />
										{{ $t("Template.ExportXML") }}
									</b-button>
								</b-input-group>
							</b-form-group>

							<!-- Template Search -->
							<b-form-group :label="$t('General.Search')" label-cols-sm="3" label-align-sm="right" label-size="sm" 
								label-for="searchTemplateInput">
								<b-input-group size="sm">
									<b-form-input v-model="filter" @input="searchTemplates()" type="search" debounce="500" id="searchTemplateInput" 
										:placeholder="$t('General.TypeToSearch')"></b-form-input>
									<template v-slot:append>
										<b-input-group-text class="icon-append">
											<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'search']"/>
										</b-input-group-text>
									</template>
								</b-input-group>
							</b-form-group>

							<!-- Template Filter -->
							<b-form-group :label="$t('General.Show')" label-cols-sm="3" label-align-sm="right" label-size="sm" 
								label-for="templateDeletedFilter" class="mt-2">
								<b-input-group size="sm">
									<b-form-select class="mr-2" id="templateDeletedFilter" size="sm" v-model="templateDeletedFilter" :options="templateDeletedOptions" @change="toggleArchivedColumn">
									</b-form-select>

									<b-form-select class="mr-2" id="templateTypeFilter" size="sm" v-model="templateTypeFilter" :options="templateTypeOptions">
									</b-form-select>

									<b-form-select v-if="$checkPermission('list-company-all')" class="mr-2" id="templateScopeFilter" size="sm" v-model="templateScopeFilter" :options="templateScopeOptions">
									</b-form-select>
									
									<b-form-select id="templateLanguageFilter" size="sm" v-model="templateLanguageFilter" :options="templateLanguageOptions">
									</b-form-select>
								</b-input-group>
							</b-form-group>

							<!-- Template switch between Cards and List, and Card display number button -->
							<b-form-group class="mt-2">
								<b-row align-h="end">
									<b-col class="col-auto px-2" v-if="isCardLayout">
										<label for="sb-inline" class="sr-only">{{ $t('General.Show') }}</label>
										<b-form-spinbutton id="sb-inline" v-model="cardPerRow" min="1" max="5" step="1" size="sm" inline></b-form-spinbutton>
									</b-col>
									<b-col class="col-auto">
										<b-button id="switchLayout" variant="primary" size="sm" class="d-block float-right" @click="isCardLayout = !isCardLayout">
											<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'eye']" />
												{{ isCardLayout? $t("Template.SwitchToList") : $t("Template.SwitchToCards") }}
										</b-button>
									</b-col>
								</b-row>
							</b-form-group>
						</div>
					</transition>
				</b-col>
			</b-row>
		
			<!-- Template Cards layout -->
			<div class="mt-3 w-100" v-show="isCardLayout">
				<b-card-group v-if="templates.length > 0">
					<cg-template-card
						v-for="(template, index) in filteredTemplates" 
						:cardPerRow="cardPerRow"
						:template="template"
						:tabIndex="2"
						:companyData="companyData"
						:canDelete="true"
						:index="index"
						:searchText="filter"  
						:key="template.template_seq"
						@preview="showPreviewModal"
					></cg-template-card>
				</b-card-group>
				<div v-if="templates.length == 0 && !loading" class="text-center line-height-200">{{ $t('General.TableFilterEmpty') }}</div>
			</div>

			<!-- Template Table layout -->
			<b-table v-show="!isCardLayout"
				id="templateListTable"
				ref="templateTable"
				:items="filteredTemplates"
				:fields="fields"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:filter="filter"
				hover striped show-empty bordered
				:emptyFilteredText="$t('General.TableFilterEmpty')"
				:empty-text="$t('General.TableEmpty')"
				@row-clicked="showPreviewModal"
				>

				<!-- Additional template info for Template type -->
				<template v-slot:cell(type)="data">
					<div class="text-center">
						<font-awesome-icon :icon="getTemplateTypeIcon(data.item.type)" />
						<font-awesome-icon v-if="data.item.has_attachment" class="over-paperclip" :icon="['fas', 'paperclip']" />
					</div>
				</template>

				<template v-slot:cell(success_company)="data">
					<div class="text-center">
						<span>{{ (Number(data.item.success_company)*100).toFixed(0) + ' %' }}</span>
					</div>
				</template>

				<template v-slot:cell(success_global)="data" v-if="$checkPermission('list-company-all')">
					<div class="text-center">
						<span>{{ (Number(data.item.success_global)*100).toFixed(0) + ' %' }}</span>
					</div>
				</template>

				<template v-slot:cell(coverage)="data">
					<div class="text-center">
						<span>{{ (Number(data.item.coverage)*100).toFixed(0) + ' %' }}</span>
					</div>
				</template>

				<template v-slot:cell(scope)="data" v-if="$checkPermission('list-company-all')">
					<div class="text-center">
						<span>{{ data.item.scope==0 ? companyData.company.name : $t('Template.Global') }}</span>
					</div>
				</template>

				<template v-slot:cell(forced)="data">
					<div class="text-center">
						<span>{{ !data.item.forced? $t('Template.NotForced') : $t('Template.Forced') }}</span>
					</div>
				</template>

				<template v-slot:cell(deleted)="row">
					<div class="text-center btn-delete-template" v-if="$checkPermission('delete-template')" :key="row.item.template_seq">
						<b-form-checkbox switch size="sm" style="z-index:9" button-variant="info"
							@input="changeDeletedStatus($event, row.item)" value="null" :unchecked-value="1" v-model="row.item.deleted">
							<span class="sr-only">{{ row.item.deleted== 1 ? $t('Template.Deleted', { name: row.item.name }) :
								$t('Template.Existing', { name: row.item.name }) }}</span>
						</b-form-checkbox>
					</div>
				</template>
			</b-table>

		</div>
	</b-container>
</template>

<script>
import templateService from "../services/template.service";
import syspropService from "../services/sysprop.service";
import companyService from "../services/company.service";
import landingpageService from "../services/landingpage.service";
import { TemplateType, TemplateScope, LandingPageScope } from '../../../server/common/constants';
import Vue from 'vue';

export default {
	data: function() {
		return {
			template: null,
			templates: [],
			fullListTemplates: [],
			showOptions: true,
			fields: [],
			filter: '',
			count: 0,
			isCardLayout: false,
			sortBy: "name",
			sortDesc: false,
			cardPerRow: 5,
			loading: false,
			companyData: null,
			companyId: localStorage.getItem('cg-company'),
			searchTimeout: null,
			templatesXml: null,
			templateDeletedFilter: null,
			templateTypeFilter: null,
			templateScopeFilter: null,
			templateLanguageFilter: null,
			alertMessage: '',
			alertParams: {},
			alertVariant: 'success',
			selectOptionString: this.$i18n.t('Template.AllLanguages'),
			TEMPLATE_TYPE_SMS: null,
			TEMPLATE_TYPE_EMAIL: null,
			TEMPLATE_TYPE_TRAINING: null,
			TEMPLATE_TYPE_QRCODE: null,
			TEMPLATE_TYPE_USB: null,
			landingpageGlobalIdOptions: null,
			landingpageIdOptions: null,
			newTemplateFields: {
				name: 'new-template',
				title: 'Template.NewTemplateTitle',
				label: 'Template.NewTemplate',
				item: { language: this.$i18n.locale },
				submitHandler: "createTemplate",
				fields:[]
			},
			countryList: null,
			imageRepoList: []
		};
	},
	computed: {
		filteredTemplates() {
			let tmp = this.templates;

			if(this.templateLanguageFilter) {
				tmp = this.fullListTemplates;
				tmp = tmp.filter((temp) => temp.body.filter((b) => b.language == this.templateLanguageFilter).length > 0);
			}

			if(this.templateTypeFilter) {
				if(this.templateTypeFilter === 'has_attachment') {
					tmp = tmp.filter((temp) => { return temp.has_attachment == 1});
				} else {
					tmp = tmp.filter((temp) => { return temp.type == this.templateTypeFilter });
				}
			}

			if(this.templateScopeFilter!= TemplateScope.GlobalAndCompany) {
				tmp = tmp.filter((temp) => { return temp.scope == this.templateScopeFilter });
			}

			if(this.templateDeletedFilter > 2) {
				// 'All Templates' shows everything but Archived templates
				tmp = tmp.filter((temp) => { return temp.deleted != 2 });
			} else {
				// Active, not active and archived are returned accordingly
				tmp = tmp.filter((temp) => { return temp.deleted == this.templateDeletedFilter });
			}

			return tmp;
		}
	},
	methods: {
		async getTemplates(created) {
			this.loading = true;
			try {
				let scope = (this.$checkPermission('list-company-all'))? TemplateScope.GlobalAndCompany : TemplateScope.Company;

				let res = await templateService.searchAllTemplates(this.filter, scope, this.companyId, this.$i18n.locale);
				this.fullListTemplates = res.data;
				
				// In order to force cards update, update :key attribute
				this.count += 1;

				// Filter duplicate Template by ID
				this.fullListTemplates = [...new Map(this.fullListTemplates.map(item => [item['template_id'], item])).values()];

				// Retrieve all content bodies for each Template
				let templateContent = await templateService.getTemplatesDetails(this.fullListTemplates.map(item => item['template_id']));

				let fullBodyTemplates = [];
				let languageList = new Set();

				// Merge info about template content in Template object for the component
				this.fullListTemplates.map(x => {
					let tc = templateContent && templateContent.data.filter(t => t && t.template_id === x.template_id);

					// Template Content may be empty if the template has not been correctly imported
					if(!tc || !tc.length) {
						x.body = [{ body: "", hints: 0 }];
						x.languages = 0;
						x.hints = 0;
					} else {
						x.body = tc;
						x.languages = tc.length;
						x.hints = this.getCurrentLangHints(tc);
						// Retrieve languages in Template contents
						x.body.forEach((l) => {
							languageList.add(l.language);
						});
					}
					x.template_seq = x.template_id + '-' + this.count;

					fullBodyTemplates.push(x);
				});

				let tempLangList = [];
				tempLangList.push({ value: null, text: this.selectOptionString });

				languageList.forEach((lang) => { 
					tempLangList.push({ value: lang, text: this.$langs[lang] });
				});

				// Enforce reactivity for template Language Array Options
				this.templateLanguageOptions = [...tempLangList];

				// Select unique Template
				this.templates = [...new Map(fullBodyTemplates.map(item => [item['template_id'], item])).values()];

				// Open the modal dialog for the Template just created
				if(created) {
					// Created is the brand new returned template_id
					setTimeout(() => {
						let justCreated = this.templates.filter(template => template.template_id == created);
						if(justCreated && justCreated.length) {
							this.showPreviewModal(justCreated[0]);
						}
					}, 500);
				}
			} catch (error) { 
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		getCurrentLangHints(data) {
			let lang = null;
			if(data.map(item => item.language).includes(this.$account.language)) {
				lang = data.filter(t => t.language === this.$account.language);
			} else if(this.companyData.company.default_language && data.map(item => item.language).includes(this.companyData.company.default_language)) {
				lang = data.filter(t => t.language === this.companyData.company.default_language);
			} else if(data.map(item => item.language).includes('en')) {
				lang = data.filter(t => t.language === 'en');
			} else {
				lang = data.filter(t => t.language === data.map(item => item.language)[0]);
			}

			return lang && lang[0] && lang[0].hints;
		},
		createNewTemplate() {
			this.cleanForm();
			this.$bvModal.show(this.newTemplateFields.name);
		},
		cleanForm() {
			this.newTemplateFields.item = {
				name: null,
				landingPage: null,
				landingPageId: null,
				tags: null,
				difficulty: null,
				type: TemplateType.Email, 
				language: this.$i18n.locale,
				companyId: this.defaultCompanyId,
				deleted: 1,
				forced: null,
				country: [{ 'key': null, 'value': this.$t('Template.AllCountries')}]
			}
		},
		async createTemplate(newTemplate) {
			console.debug("Template - createTemplate with values", newTemplate);
			newTemplate.currCompanyId = this.companyData.company_id;

			// Prepare country field as stored into the database
			let cList = newTemplate.country.map(c => c.key);
			newTemplate.country = cList && cList[0]? cList.join('#') : null;

			try {
				let res = await templateService.createTemplate(newTemplate);
				this.alertVariant = 'success';
				this.alertMessage = 'Template.CreateSuccess';

				let data = {
					language: newTemplate.language? newTemplate.language : this.$i18n.locale,
					type: newTemplate.type 
				};

				// In case of SMS Template type, fields such as subject and sender must be set by default
				if(newTemplate.type == this.TEMPLATE_TYPE_SMS) {
					data.sender = 'noreply@cgphishing.com';
					data.subject = 'GoPhish email subject';
				}

				await templateService.createTemplateContent(res.data.template_id, data);
				this.getTemplates(res.data.template_id);	
			} catch (error) {
				this.alertVariant = 'danger';
				this.alertMessage = 'Template.CreateError';
			} finally {
				this.$refs.cgLocAlert.showAlert();
			}
		},
		async changeDeletedStatus(event, template) {
			console.debug("Template - changeDeletedStatus", event, template);

			templateService.changeDeletedStatus(template.template_id, template.deleted).then(() => {
				console.debug("Template - changeDeletedStatus OK");
				template.deleted = Number(template.deleted) == 1 ? 1 : null;
			}).catch((error) => {
				console.error("Template - changeDeletedStatus encountered an error", error);
			});
		},
		async alignRankings() {
			templateService.alignRankings().then(() => {
				console.debug("Template - alignRankings OK");
				
				this.getTemplates();
			}).catch((error) => {
				console.error("Template - alignRankings encountered an error", error);
			});
		},
		deleteTemplate(index, item) {
			// Unimplemented
			console.log('deleteTemplate', index, item);
		},
		searchTemplates() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}
			this.searchTimeout = setTimeout(this.getTemplates, 700);
		},
		async importTemplates() {
			if(this.templatesXml) {
				this.loading = true;
				let templatesFormData = new FormData();
				templatesFormData.append('templatefile', this.templatesXml);
				try {
					let resultImport = await templateService.importTemplate(templatesFormData);
					console.log("importTemplates -> resultImport", resultImport);
					var msgParams = {
						processed: resultImport.data.total,
						created: resultImport.data.created,
						updated: resultImport.data.updated,
						hints: resultImport.data.hints
					};

					this.alertVariant = 'success';
					this.alertMessage = 'Template.ImportSuccess';
					this.alertParams = msgParams;
					this.$refs.cgLocAlert.showAlert();
					this.getTemplates();

					// we no longer align rankings as it takes too long
					// this.alignRankings();
				} catch (error) {
					this.alertVariant = 'danger';
					let errorMsg = this.$i18n.t('Template.' + error.response.data.error);
					this.alertMessage = 'Template.ImportError';
					this.alertParams = { error : errorMsg? errorMsg : error.response.data.error };
					this.$refs.cgLocAlert.showAlert();
				} finally {
					this.loading = false;
					this.templatesXml = null;
				}
			}
		},
		showPreviewModal(record) {
			this.template = record;

			this.$refs.cgTemplateModal.showPreviewModal(this.template);
		},
		updateRepo(index, image) {
			console.debug("Image uploaded", index, image.name);
			// A new image has been uploaded into the repository
			if(index > -1) {
				this.imageRepoList[index] = image;
			} else {
				this.imageRepoList.push(image);
			}
		},
		getTemplateTypeIcon(templateType) {
			switch(templateType) {
				case this.TEMPLATE_TYPE_SMS:
					return ['fas', 'sms'];
				case this.TEMPLATE_TYPE_TRAINING:
					return ['fas', 'chalkboard-teacher'];
				case this.TEMPLATE_TYPE_QRCODE:
					return ['fas', 'qrcode'];
				case this.TEMPLATE_TYPE_USB:
					return ['fab', 'usb'];
				case this.TEMPLATE_TYPE_EMAIL:
				default:
					return ['fas', 'envelope'];
			}
		},
		reloadTemplate(templateId, func, attr, data) {
			// Template was changed in its body, the Card should reflect those very changed
			this.count += 1;

			// In order to trigger computed data reload (filteredTemplates), an update in this.templates should be triggered
			let template_index = this.templates.findIndex(i => i.template_id === templateId);
			let template = this.templates.filter((i) => { if (i.template_id === templateId) { i.template_seq = templateId + '-' + this.count; return i; } });

			if(func && data) {
				template[0][attr] = this[func](data);
			}
			
			// Vue.set enforce this.templates reactivity in depth
			Vue.set(this.templates, template_index, template[0]);
		},
		reloadHint(data) {
			console.debug("Template - ReloadTemplate - reloadHint");
			return this.getCurrentLangHints([...data]);
		},
		reloadBody(data) {
			console.log("Template - ReloadTemplate - ReloadBody");
			return [...data];
		},
		toggleArchivedColumn() {
			// Find the column by key
			const column = this.fields.find(field => field.key === 'deleted');

			// If the column exists, toggle its visibility
			if (column && this.templateDeletedFilter == 2) {
				const index = this.fields.indexOf(column);
				if (index !== -1) {
					// If it's currently visible, remove it from fields to hide it
					this.fields.splice(index, 1);
				}
			} else if(this.$checkPermission('delete-template')) {
				// If it's hidden, re-add it to show it
				this.fields.push({ key: 'deleted', label: this.$t('Template.IsDeleted'), sortable: false, thClass: "type-column" });
			}
		},
		copied(alertMessage, alertVariant) {
			this.alertVariant = alertVariant;
			this.alertMessage = alertMessage;

			this.$refs.cgLocAlert.showAlert();
			this.getTemplates();
		},
		disableForced(value) {
			if(Number(value) == 1) {
				Vue.set(this.newTemplateFields.fields.find(i => i.id === 'forced'), 'value', null);
				Vue.set(this.newTemplateFields.fields.find(i => i.id === 'forced'), 'disabled', true);
			} else {
				Vue.set(this.newTemplateFields.fields.find(i => i.id === 'forced'), 'disabled', false);
			}
		},
		addTag(model, id) {
			// Remove null items from 'id' list
			model[id] = model[id].filter((item) => item.key);
			this.newTemplateFields.item = model;

			Vue.set(this.newTemplateFields.fields.find(i => i.id === id), 'placeholder', null);
		},
		removeTag(model, id) {
			this.newTemplateFields.item = { ...model, [id] : [{ 'key': null, 'value': this.$t('Template.AllCountries')}] };

			Vue.set(this.newTemplateFields.fields.find(i => i.id === id), 'placeholder', 'Template.AvailableCountryPlaceholder');
		},
		setNewFields(typeOptions, languageOptions, companyOptions) {
			this.newTemplateFields.fields.push(
				{ id: "name", label: 'Template.Name', type: "text", required: true, display: true, placeholder: 'Template.Name'},
				{ id: "companyId", label: 'Template.Company', type: "select", display: true, options: companyOptions});
			
			if (this.$checkPermission('view-landing-page'))	this.newTemplateFields.fields.push(
				{ id: "landingPageId", label: 'Template.LandingPageId', type: "select", display: true, options: []},
				{ id: "landingPage", label: 'Template.LandingPage', type: "text", display: true, placeholder: 'Template.LandingPage'}
			);

			this.newTemplateFields.fields.push(
				{ id: "type", label: 'Template.Type', type: "select", display: true, options: typeOptions},
				{ id: "language", label: 'Template.Language', type: "select", display: true, placeholder: 'Template.Language', options: languageOptions},
				{ id: "difficulty", label: 'Template.Difficulty', type: "number", min: 0, max: 1, value: 0, step: 0.1, display: true, placeholder: 'Template.DifficultyPlaceholder'},
				{ id: "tags", label: 'Template.Tags', type: "textarea", display: true, placeholder: 'Template.Tags'},
				{ id: "deleted", label: 'Template.IsDeleted', type: "simple-checkbox", display: true, value: null, unchecked_value: 1, change: this.disableForced },
				{ id: "forced", label: 'Template.IsForced', type: "simple-checkbox", display: true, value: 1, unchecked_value: null },
				{ id: "country", label: 'General.Country', type: "tag-input", display: true, placeholder: 'Template.AvailableCountryPlaceholder', options: this.countryList, plural: 2,
					manage: this.addTag, clean: this.removeTag }
			);
		},
		async getImageRepo() {
			let repo = await templateService.getImageRepo();
			this.imageRepoList = repo && repo.data;
		},
        async setLandingPage() {
            let lands = await landingpageService.getLandingPages(this.companyId, LandingPageScope.GlobalAndCompany);
            this.landingpageGlobalIdOptions = lands.data.filter(lp => { return lp.scope == LandingPageScope.Global })
                                              .sort((a, b) => { return a.page_id > b.page_id; })
                                              .map((temp) => { return { value: temp.page_id, text: this.$i18n.t('Template.LandingPageNameGlobal', { id: temp.page_id, name: temp.name }) } });
            this.landingpageGlobalIdOptions.unshift({ value: null, text: this.$i18n.t('Company.DefaultLandingPagePlaceholder') });
			
			this.landingpageIdOptions = lands.data.sort((a, b) => { return (a.scope > b.scope); })
												.sort((a, b) => { return (a.scope == b.scope) && (a.page_id > b.page_id); })
												.map((temp) => {
														let lpname=(temp.scope == LandingPageScope.Global)? this.$i18n.t('Template.LandingPageNameGlobal', { id: temp.page_id, name: temp.name }): this.$t('Template.LandingPageName', { id: temp.page_id, name: temp.name });
														return { value: temp.page_id, text: lpname }});
			this.landingpageIdOptions.unshift({ value: null, text: this.$i18n.t('Company.DefaultLandingPagePlaceholder') });
			this.setLandingPageOptions(this.defaultCompanyId);

			Vue.set(this.newTemplateFields.fields.find(i => i.id === 'companyId'), 'change', this.changeLandingPageByCompany);
		},
		async setLandingPageOptions(selectedValue) {
			if (!selectedValue) { // global
				Vue.set(this.newTemplateFields.fields.find(i => i.id === 'landingPageId'), 'options', this.landingpageGlobalIdOptions);
			} else {
				Vue.set(this.newTemplateFields.fields.find(i => i.id === 'landingPageId'), 'options', this.landingpageIdOptions);
			}
		},
		changeLandingPageByCompany(selectedValue) {
			this.setLandingPageOptions(selectedValue);
		},
	},
	async created() {
		this.loading = true;

		this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
		this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;
		this.TEMPLATE_TYPE_QRCODE = TemplateType.QRCode;
		this.TEMPLATE_TYPE_USB = TemplateType.USB;
		this.TEMPLATE_TYPE_TRAINING = TemplateType.Training;

		this.templateDeletedOptions = [
			{ value: null, text: this.$t('Template.Active') },
			{ value: 1, text: this.$t('Template.NotActive') },
			{ value: 3, text: this.$t('Template.AllTemplates') }
		];

		this.templateTypeOptions = [
			{ value: null, text: this.$t('Template.AllTypes') },
			{ value: this.TEMPLATE_TYPE_EMAIL, text: this.$t('Template.EmailOnly') },
			{ value: this.TEMPLATE_TYPE_SMS, text: this.$t('Template.SMSOnly') },
			{ value: 'has_attachment', text: this.$t('Template.AttachmentOnly') }
		];

		if (this.$checkPermission('list-company-all')) {
			this.templateDeletedOptions.push({ value: 2, text: this.$t('Template.Archived') });
			
			this.templateTypeOptions.push( 
				{ value: this.TEMPLATE_TYPE_QRCODE, text: this.$t('Template.QRCodeOnly') }, 
				{ value: this.TEMPLATE_TYPE_USB, text: this.$t('Template.USBOnly') },
				{ value: this.TEMPLATE_TYPE_TRAINING, text: this.$t('Template.TrainingOnly') }
			);	
		}

		this.templateScopeOptions = [
			{ value: TemplateScope.GlobalAndCompany, text: this.$t('Template.GlobalAndCompany') },
			{ value: TemplateScope.Global, text: this.$t('Template.Global') },
			{ value: TemplateScope.Company, text: this.$t('Template.Company') }
		];

		this.templateLanguageOptions = [
			{ value: null, text: this.$t('Template.AllLanguages') }
		];

		const config = await syspropService.getConfig();
		this.companyData = { 
			company_id: this.companyId,
			contact: config.data.defaults.contact,
			cgsmtp: config.data.defaults.cgsmtp,
			gp_landing_url: config.data.defaults.gp_landing_url,
			img_url: config.data.defaults.campaign.img_url || `{{.BaseURL}}`
		};
		
		if(this.companyId) {
			let companyRes = await companyService.getCompany(this.companyId);
			this.companyData.company = companyRes.data;
		}

		let templateTypeOptions = [
			{ value: this.TEMPLATE_TYPE_EMAIL, text: this.$t('Analytics.TemplateTypes.Email') }, 
			{ value: this.TEMPLATE_TYPE_SMS, text: this.$t('Analytics.TemplateTypes.Sms') },
			{ value: this.TEMPLATE_TYPE_QRCODE, text: this.$t('Analytics.TemplateTypes.QRCode') },
			{ value: this.TEMPLATE_TYPE_USB, text: this.$t('Analytics.TemplateTypes.USB') },
			{ value: this.TEMPLATE_TYPE_TRAINING, text: this.$t('Analytics.TemplateTypes.Training') }
		];

		// Retrieve all countries
		this.isoCountries = await this.$isoCountriesLanguages.getCountries(this.$i18n.locale);

		// Modify isoCountries to fit tag-input options
		this.countryList = Object.keys(this.isoCountries).map((c) => { return { key: c, value: this.isoCountries[c]} });

		this.defaultCompanyId = this.companyId;

		let companyOptions = [];

		// Check if the logged user is admin
		if (this.$checkPermission('list-company-all')) {
			this.defaultCompanyId = null;
			companyOptions.push( { value: null, text: this.$t('Template.Global') } );	
		}

		companyOptions.push({ value: this.companyId, text: this.companyData.company.name });

		this.setNewFields(templateTypeOptions, this.$langs, companyOptions);

		if (this.$checkPermission('view-landing-page')) {
			this.setLandingPage();
		}

		this.fields = [
			{ key: 'name', label: this.$t('Template.Name'), sortable: true, class: "text-ellipsis" },
			{ key: 'tags', label: this.$t('Template.Tags'), sortable: true },
			{ key: 'hints', label: this.$t('Template.NumHints'), sortable: true },
			{ key: 'languages', label: this.$t('Template.Languages'), sortable: true },
			{ key: 'success_company', label: this.$t('Template.DifficultyCompany'), sortable: true, class: 'd-none d-xl-table-cell' }
		];
		
		if(this.$checkPermission('list-company-all')) {
			this.fields.push({ key: 'success_global', label: this.$t('Template.DifficultyGlobal'), sortable: true, class: 'd-none d-xl-table-cell' });
		}

		this.fields.push({ key: 'coverage', label: this.$t('Template.CompanyCoverage'), sortable: true, class: 'd-none d-xl-table-cell' });

		if(this.$checkPermission('list-company-all')) {
			this.fields.push({ key: 'scope', label: this.$t('Template.Scope'), sortable: true, class: 'd-none d-xl-table-cell' });
		}
		
		this.fields.push({ key: 'type', label: this.$t('Template.Type'), sortable: true, thClass: "type-column" });

		this.fields.push({ key: "forced", label: this.$t('Template.IsForced'), sortable: true });

		if(this.$checkPermission('delete-template')) {
			this.fields.push({ key: 'deleted', label: this.$t('Template.IsDeleted'), sortable: false, thClass: "type-column" });
		}

		if (this.$checkPermission('list-company-all')) {
			this.templateScopeFilter = TemplateScope.GlobalAndCompany; 
		} else { // Limit the scope to company
			this.templateScopeFilter = TemplateScope.Company;
		}

		this.getTemplates();

		// Retrieve all images from repo
		this.getImageRepo();
	}
};
</script>
 
<style lang="less">
.template-title {
	font-size: 1.3em;
}
.type-column {
	width: 50px;
}
.template-menu {
	border-top: @cg-border-light;
}
.fade-enter-active, .fade-leave-active {
	transition: all 2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
#templateListTable tr {
	cursor: pointer;
}
.over-paperclip {
	position: absolute;
	width: 13px;
	height: 13px;
	margin: 9px -4px;
	stroke: white;
	stroke-width: 30px;
}
</style>
